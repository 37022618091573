import React, {useState} from "react"
import {Container,Table, Dropdown, Row, Col, Button, Form} from 'react-bootstrap';
import { Link, navigate } from "gatsby";
import LeftNav from "../components/Common/leftNav";
import SEO from "../components/seo"
import PropertyRightSide from "../components/modules/property-right-side"
import PropertyTab from "../components/modules/property-tab"

import { useContainerContext } from "../store/ContainerContext";
import { UpdateAdditionalNotes } from "../queries/api_links";

const AdditionalNotes = (props) => {
  let path_name = (props.location.pathname).split('/');
  let get_property_id = path_name[path_name.length-1];

  const context = useContainerContext();
  const { property_data } = context.state;
  const { propertyDetDispatch} = context.actions;

  const [additional_notes, setAdditionalNotes] = useState(property_data && property_data.additional_notes ? property_data.additional_notes : '');

  const [succ_msg, setSuccMsg] = useState('');
  const [succ_cls, setSuccCls] = useState('');

  const handleChange = (evt) =>{
    setAdditionalNotes(evt.target.value);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    var post_data = additional_notes;
    var res = UpdateAdditionalNotes(post_data, get_property_id).then(res => {
      if(res.status === 200){
        setSuccMsg("Success! Additional notes is saved.");
        setSuccCls('success');
        propertyDetDispatch(res.data);
        //console.log("res_data", res.data)
      } else{
        setSuccMsg("Error! Could not saved.");
        setSuccCls('danger');
      }
    });

    
    //console.log("additional_notes", additional_notes);
  }

  //console.log("property_data", property_data);

  return(

    <>
      <SEO title="Photo Gallery" description="Photo Gallery" />
      <div className="dashboard-wrapper">
        <LeftNav page_name="Photo Gallery" />
        {/* sidebar */}

        <div className="dashboard-content seller_cnt">
          <Container>
              <div className="content-wrapper ">

                    <PropertyTab tab_name="Additional Notes" get_property_id={get_property_id} />
                    {/* Tab Content */}

                    <div className="sell_properties">
                        <Row>
                            
                            <Col lg={12} md={12}>
                                <Row>
                                    <Col lg={8} md={8}>
                                      {succ_msg && <div className={`alert alert-${succ_cls}`}>{succ_msg}</div>}

                                      <Form id="additional-notes-form" className="additional-notes-form" action={"javascript:;"} method="post" onSubmit={handleSubmit}>
                                        <div className="additional_cls">
                                          <textarea name="additional_notes" id="additional_notes" onChange={handleChange}>{additional_notes}</textarea>
                                        </div>
                                        <Button variant="primary" id="save_notes" className="col-md-3 mt-4 float-right" type="submit">Save</Button>
                                      </Form>
                                    </Col>

                                    <PropertyRightSide />
                                    
                                </Row>                               
                            </Col>    
                        </Row>
                    </div>
              </div>

              
          </Container>
          {/* container */}
        </div>
        {/* dashboard-content */}
      </div>
    </>
  )

}

export default AdditionalNotes